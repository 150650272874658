import React from 'react';
import {
	FaFacebook,
	FaInstagram,
	FaYoutube,
	FaTiktok,
	FaLinkedin,
} from 'react-icons/fa';
import {
	FooterContainer,
	FooterWrap,
	SocialMedia,
	SocialMediaWrap,
	SocialLogo,
	SocialIcons,
	SocialIconLink, EnlacesLinks,
	ListadoLink,
	ItemLink, ItemTitle, SocialMediaColumn
} from './FooterElements';

function Footer() {
	return (
		<FooterContainer>
			<FooterWrap>
				<SocialMedia>

					<SocialMediaColumn>
						<SocialLogo to='/'>Suerte viajera</SocialLogo>
					</SocialMediaColumn>
					<SocialMediaColumn>

						<ListadoLink>
							<ItemTitle>Algo de nosotros</ItemTitle>

							<ItemLink href='/quienes-somos'>Quienes somos</ItemLink>
							<ItemLink href='/vuelos?tipo=nacionales'>Sorteos Nacionales</ItemLink>
							<ItemLink href='/vuelos?tipo=internacionales'>Sorteos Internacionales</ItemLink>
							<ItemLink href='/ganadores'>Resultados</ItemLink>

						</ListadoLink>


					</SocialMediaColumn>
					<SocialMediaColumn>
						<ListadoLink>
							<ItemTitle>Redes Sociales</ItemTitle>
							<SocialIcons>
								<SocialIconLink
									href='https://www.facebook.com/profile.php?id=100092648281211&mibextid=ZbWKwL'
									target='_blank'
									aria-label='Facebook'
									rel='noopener noreferrer'
								>
									<FaFacebook />
								</SocialIconLink>
								<SocialIconLink
									href='https://www.instagram.com/lasuerte_viajera/'
									target='_blank'
									aria-label='Instagram'
									rel='noopener noreferrer'
								>
									<FaInstagram />
								</SocialIconLink>
								<SocialIconLink
									href='https://www.youtube.com/channel/UCmc-QuQ1qrLGF4P9kO0ChEA'
									target='_blank'
									aria-label='YouTube'
									rel='noopener noreferrer'
								>
									<FaYoutube />
								</SocialIconLink>
								<SocialIconLink
									href='https://www.tiktok.com/@suerteviajera?lang=es'
									target='_blank'
									aria-label='Twitter'
									rel='noopener noreferrer'
								>
									<FaTiktok />
								</SocialIconLink>

							</SocialIcons>
						</ListadoLink>
						<ListadoLink>
							<ItemTitle>Soporte</ItemTitle>
							<ItemLink href=''>Contacto</ItemLink>
							<ItemLink href='/terminos-y-condiciones'>Terminos y condiciones</ItemLink>

						</ListadoLink>


					</SocialMediaColumn>
				</SocialMedia>
			</FooterWrap>
		</FooterContainer>
	);
}

export default Footer;
