
/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import "./styles.css";
import useScreenSize from "../../../hooks/useScreenSize";

export const Newsletter = () => {

    const { width, height } = useScreenSize();

    if (width >= 1440) {
        return (
            <div className="desktop-whoiam-container">
                <DesktopWhoiam className="desktop-whoiam-suscriber" />
            </div>
        )
    }

    if (width >= 768) {
        return (
            <div className="tablet-whoiam-container">
                <TabletWhoaiam className="tablet-whoaiam-surcriber" />
            </div>
        )
    }


    return (
        <div className="newsletter">
            <div className="mobile-whoiam-wrapper">
                <MobileWhoiam className="mobile-whoiam-suscribirte" />
            </div>
        </div>
    );
};

const DesktopWhoiam = ({ className }) => {
    return (
        <div className={`desktop-whoiam ${className}`}>
            <div className="overlap">
                <img
                    className="mask-group"
                    alt="Mask group"
                    src="https://generation-sessions.s3.amazonaws.com/6af115a2f34204b9b3fb3caf47a7f31e/img/mask-group-1.png"
                />
                <div className="rectangle" />
                <div className="div" />
                <p className="suscr-bete-y-recibe">
                    <span className="text-wrapper">Escríbenos y </span>
                    <span className="span">recibe un ticket gratis en tú primera compra</span>
                </p>
                <img
                    className="vector"
                    alt="Vector"
                    src="https://generation-sessions.s3.amazonaws.com/6af115a2f34204b9b3fb3caf47a7f31e/img/vector-13-1.svg"
                />
                <img
                    className="img"
                    alt="Mask group"
                    src="https://generation-sessions.s3.amazonaws.com/6af115a2f34204b9b3fb3caf47a7f31e/img/mask-group-3@2x.png"
                />
                <p className="est-s-listo-para-tu">¿Estás listo para tu próximo viaje?</p>
                <img
                    className="vector-2"
                    alt="Vector"
                    src="https://generation-sessions.s3.amazonaws.com/6af115a2f34204b9b3fb3caf47a7f31e/img/vector-11-1.svg"
                />
                <img
                    className="vector-3"
                    alt="Vector"
                    src="https://generation-sessions.s3.amazonaws.com/6af115a2f34204b9b3fb3caf47a7f31e/img/vector-11-1.svg"
                />
                <div className="group">

                    <div className="suscribirme-wrapper">
                        <div className="suscribirme">Escríbenos</div>
                    </div>
                </div>
                <p className="suerte-viajera-es">
                    Suerte Viajera es una plataforma de sorteos para los apasionados a los viajes que desean explorar nuevos
                    destinos de una manera rápida, emocionante e inesperada.
                </p>
                <div className="qui-nes-somos">¿Quiénes somos?</div>
            </div>
        </div>
    );
};


const TabletWhoaiam = ({ className }) => {
    return (
        <div className={`tablet-whoaiam ${className}`}>
            <div className="overlap">
                <div className="group">
                    <div className="overlap-group">
                        <div className="mask-group">
                            <img
                                className="adobestock"
                                alt="Adobestock"
                                src="https://generation-sessions.s3.amazonaws.com/fb7056e21a632820dcc619e78a3b5281/img/adobestock-527243669-1-1@2x.png"
                            />
                        </div>
                    </div>
                </div>
                <div className="rectangle" />
                <div className="overlap-wrapper">
                    <div className="suscribirme-wrapper">
                        <a className="suscribirme" href="https://api.whatsapp.com/send?phone=51941838854&text=Hola%20Suerte%20Viajera%2C%20deseo%20que%20me%20apuntes%20para%20tener%20mi%20ticket%20gratis%20en%20mi%20primera%20jugada.%20%F0%9F%A4%A9" >Escríbenos</a>
                    </div>
                </div>
                <p className="est-s-listo-para-tu">¿Estás listo para tu próximo viaje?</p>
                <p className="suscr-bete-y-recibe">
                    <span className="text-wrapper">Escríbenos y </span>
                    <span className="span">recibe un ticket gratis en tú primera compra</span>
                </p>
                <img
                    className="vector"
                    alt="Vector"
                    src="https://generation-sessions.s3.amazonaws.com/fb7056e21a632820dcc619e78a3b5281/img/vector-11-1.svg"
                />
                <img
                    className="img"
                    alt="Vector"
                    src="https://generation-sessions.s3.amazonaws.com/fb7056e21a632820dcc619e78a3b5281/img/vector-11-1.svg"
                />
                <img
                    className="mask-group-2"
                    alt="Mask group"
                    src="https://generation-sessions.s3.amazonaws.com/fb7056e21a632820dcc619e78a3b5281/img/mask-group-1@2x.png"
                />
            </div>
            <p className="suerte-viajera-es">
                <span className="text-wrapper-2">Suerte Viajera es una </span>
                <span className="text-wrapper-3">plataforma de sorteos</span>
                <span className="text-wrapper-2">
                    {" "}
                    para los apasionados a los viajes que desean explorar nuevos destinos de una manera rápida, emocionante e
                    inesperada. <br />
                </span>
            </p>
            <div className="overlap-2">
                <img
                    className="vector-2"
                    alt="Vector"
                    src="https://generation-sessions.s3.amazonaws.com/fb7056e21a632820dcc619e78a3b5281/img/vector-13-1.svg"
                />
                <div className="qui-nes-somos">¿Quiénes somos?</div>
            </div>
        </div>
    );
};



const MobileWhoiam = ({ className }) => {
    return (
        <div className={`mobile-whoiam ${className}`}>
            <div className="overlap-group">
                <img
                    className="rectangle"
                    alt="Rectangle"
                    src="https://generation-sessions.s3.amazonaws.com/df2e798443d4fafe58eae72be49b9181/img/rectangle-1491-1@2x.png"
                />
                <div className="div" />
                <a className="rectangle-3" href="https://api.whatsapp.com/send?phone=51941838854&text=Hola%20Suerte%20Viajera%2C%20deseo%20que%20me%20apuntes%20para%20tener%20mi%20ticket%20gratis%20en%20mi%20primera%20jugada.%20%F0%9F%A4%A9" ></a>
                <a className="suscribirme" href="https://api.whatsapp.com/send?phone=51941838854&text=Hola%20Suerte%20Viajera%2C%20deseo%20que%20me%20apuntes%20para%20tener%20mi%20ticket%20gratis%20en%20mi%20primera%20jugada.%20%F0%9F%A4%A9" >Registrarme</a>
                <p className="est-s-listo-para-tu">¿Estás listo para tu próximo viaje?</p>
                <p className="suscr-bete-y-recibe">
                    <span className="text-wrapper">Envíanos un mensaje y </span>
                    <span className="span">recibe un ticket gratis en tú primera jugada</span>
                </p>
                <img
                    className="vector"
                    alt="Vector"
                    src="https://generation-sessions.s3.amazonaws.com/df2e798443d4fafe58eae72be49b9181/img/vector-11-1.svg"
                />
                <img
                    className="img"
                    alt="Vector"
                    src="https://generation-sessions.s3.amazonaws.com/df2e798443d4fafe58eae72be49b9181/img/vector-11-1.svg"
                />
                <img
                    className="mask-group"
                    alt="Mask group"
                    src="https://generation-sessions.s3.amazonaws.com/df2e798443d4fafe58eae72be49b9181/img/mask-group-1@2x.png"
                />
                <div className="adobestock-wrapper">
                    <img
                        className="adobestock"
                        alt="Adobestock"
                        src="https://generation-sessions.s3.amazonaws.com/df2e798443d4fafe58eae72be49b9181/img/adobestock-527243669-1-1@2x.png"
                    />
                </div>
            </div>
            <p className="suerte-viajera-es">
                <span className="text-wrapper-2">Suerte Viajera es una </span>
                <span className="text-wrapper-3">plataforma de sorteos</span>
                <span className="text-wrapper-2">
                    {" "}
                    para los apasionados a los viajes que desean explorar nuevos destinos de una manera rápida, emocionante e
                    inesperada. <br />
                </span>
            </p>
            <div className="overlap">
                <img
                    className="vector-2"
                    alt="Vector"
                    src="https://generation-sessions.s3.amazonaws.com/df2e798443d4fafe58eae72be49b9181/img/vector-13-1.svg"
                />
                <div className="qui-nes-somos">¿Quiénes somos?</div>
            </div>
        </div>
    );
};