import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';
import { FaUserCircle } from 'react-icons/fa';

export const Nav = styled.nav`
	background: transparent;
	height: 60px;
	display: flex;
	justify-content: space-between;
	font-weight: 700;
	position: fixed;
	width: 100%;
    top: 0;
    z-index: 40;
	background: ${props => props.navChanged ? "#ffc500" : "transparent"};
	background: ${props => props.pathname == "/" ? 1 : "#ffc500"};

`;

export const NavContainer = styled.div`
	background: transparent;
	height: 60px;
	display: flex;
	justify-content: space-between;
	font-weight: 700;
	width: 100%;
    top: 0;
	max-width: 1200px;
	margin:auto;
	background: ${props => props.navChanged ? "#ffc500" : "transparent"};
	background: ${props => props.pathname == "/" ? 1 : "#ffc500"};
	padding-left: 1.1rem;
	padding-right: 1.1rem;
`;

export const NavLink = styled(Link)`
	color: #004AAD;
	display: flex;
	align-items: center;
	text-decoration: none;
	cursor: pointer;
	font-size:1.2rem;

	@media(min-width: 600px) {
		font-size: 2rem;

	}
`;

export const NavIcon = styled.div`
	display: flex;
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;
	color: #fff;

	p {
		transform: translate(-175%, 100%);
		font-weight: bold;
	}
`;

export const Bars = styled(FaUserCircle)`
	font-size: 2rem;
	margin: 16px;
	z-index: 30;
	@media screen and (max-width: 400px) {
		margin: 1rem 1.5rem;
	}
`;


export const IconNavbar = styled.img`
	font-size: 2rem;
	margin: 16px;
	z-index: 30;
	@media screen and (max-width: 400px) {
		margin: 1rem 1.5rem;
	}
	width: 35px;
	height:35px;
	border-radius: 50%;
`;

export const IconsLink = styled.a`
	display: flex;
	justify-content: space-around;
	align-items: center;
`

export const IconLinkLogin = styled.a`
	display: flex;
    padding: 7px 15px;
    border: 1.5px solid #004AAD;
    align-items: center;
    font-weight: 400;
    height: fit-content;
    border-radius: 40px;
    color: #004AAD;
	font-size: 11px;
	text-align:center;

	@media(min-width: 600px) {
			font-size: 14px;
    		padding: 7px 15px;

	}
`


export const IconLinkRegister = styled.a`
	display: flex;
	padding: 10px 15px;
	display: flex;
    padding: 7px 15px;
    align-items: center;
    font-weight: 400;
    height: fit-content;
    border-radius: 40px;
    border: 1.5px solid #004AAD;
	background: #004AAD;
    color: #fff;
	margin-left: 10px;
	font-size: 11px;

	@media(min-width: 600px) {
			font-size: 14px;

	}
`