import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const FooterContainer = styled.footer`
	background-color: #004AAD;
	padding-top:20px;
	padding-bottom:20px;
	
`;

export const FooterWrap = styled.div`
	padding: 16px 24px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 1300px;
	margin: 0 auto;
	
`;

export const SocialMedia = styled.section`
	max-width: 1300px;
	width: 100%;
	display:flex;
	flex-wrap: wrap;
	justify-content: center;
    text-align: center;
@media(min-width: 720px) {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: 1fr;
		grid-column-gap: 20px;
		grid-row-gap: 0px;
		justify-content: center;
    	text-align: left;
	} ;

`;

export const SocialMediaWrap = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 1100px;
	margin: 16px auto 0 auto;

	@media screen and (max-width: 820px) {
		flex-direction: column;
	} ;
`;

export const SocialLogo = styled(Link)`
	color: #fff;
	justify-self: start;
	cursor: pointer;
	text-decoration: none;
	font-size: 1.5rem;
	display: flex;
	align-items: center;
	margin-bottom: 16px;
	font-weight: bold;
`;

export const SocialIcons = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 240px;
	margin-bottom: 15px;
`;

export const SocialIconLink = styled.a`
	color: #fff;
	font-size: 24px;
`;

export const EnlacesLinks = styled(Link)`
	color: #fff;
	justify-self: start;
	cursor: pointer;
	text-decoration: none;
	font-size: 1rem;
	display: flex;
	align-items: center;
	margin-bottom: 16px;
	font-weight: light;
	text-decoration: underline;
`;


export const ListadoLink = styled.ul`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 240px;
	list-decoration: 
`;

export const ItemLink = styled.a`
	color: white;
	font-size: 13px;
	padding: 6px 20px;
`

export const ItemTitle = styled.h5`
	color: white;
	font-size: 17px;
	margin-bottom: 15px;
	font-weight: 700;
`

export const SocialMediaColumn = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`