import React, { useEffect, useState } from 'react';
import { getListVuelos } from '../api/gets';
import Header from '../components/prelaunch/Header';
import Hero from '../components/prelaunch/Hero';
import { Carousel } from '../components/prelaunch/Carousel';
import { Cintillo } from '../components/prelaunch/Cintillo';
import { CountdownTimer } from '../components/prelaunch/CountdownTimer';
import { Newsletter } from '../components/prelaunch/Newsletter';
import Footer from '../components/prelaunch/Footer';
const Home = () => {

    return (
        <div>
            <Header></Header>
            <Hero></Hero>
            <Cintillo></Cintillo>
            <Newsletter></Newsletter>
            <Footer></Footer>
        </div>
    )
}

export default Home