import styled from 'styled-components';

export const ProductsContainer = styled.div`
	/* width: 100vw; */
	min-height: 100vh;
	padding: 5rem calc((100vw - 1300px) / 2);
	color: #fff;
	padding-top: 1rem;
`;

export const ProductsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 0 auto;
`;

export const ProductsCard = styled.div`
	margin: 2rem;
	line-height: 2;
    line-height: 2;
    padding: 20px;
    max-width: 357px;
    border-radius: 35px;
	background: #ffc500;

`;

export const ProductsImg = styled.img`
	height: 300px;
	min-width: 300px;
	max-width: 100%;
	border-radius:20px;
`;

export const ProductsHeading = styled.h1`
	font-size: clamp(2rem, 2.5vw, 3rem);
	text-align: center;
	margin-bottom: 1rem;
	position: sticky;
	z-index:20;
	top: 0;
	width:100%;
	background:black;
	padding: 0.4rem 2rem;
`;

export const SelectedCategoryHeading = styled.h1`
	font-size: clamp(0.9rem, 2vw, 2.8rem);
	text-align: center;
	position: sticky;
	z-index:20;
	top: 0;
	width:100%;
	padding: 0;
	margin: auto;
	color: white;
`;

export const CategoryHeading = styled.div`
	font-size: clamp(2rem, 2.5vw, 3rem);
	text-align: center;
	margin-bottom: 5rem;
	display: flex;
	align-center:center;
	justify-content: space-around;
	margin:auto;
	margin-bottom: 1.5rem;
	/*position: sticky;*/
	
	z-index:20;
	top: 60px;
	
	width:100%;
	@media screen and (min-width: 650px) {
		
		flex-direction: row;
		padding: 1rem 2rem;
	}
`;

export const ProductsTitle = styled.h2`
	font-weight: 400;
	font-size: 1.5rem;
`;

export const ProductsInfo = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 2rem;
	text-align: center;
	padding-top: 1.2rem;
	color:black;

`;

export const ProductsDesc = styled.p`
	margin-bottom: 1rem;
	color:black;

`;

export const ProductsPrice = styled.p`
	margin-bottom: 1rem;
	font-size: 2rem;
	color:black;
`;

export const ProductsButton = styled.button`
	font-size: 1rem;
	padding: 1rem 4rem;
	border: none;
	background: #004AAD;
	color: #fff;
	transition: 0.2s ease-out;

    padding: 0.3rem 1.2rem;
    border-radius: 40px;
	&:hover {
		background: #ffc500;
		transition: 0.2s ease-out;
		cursor: pointer;
		color: #fff;
	}
	
`;

export const CategoryButton = styled.button`
	font-size: 1rem;
	padding: 0.5rem 1.2rem;
	border: none;
	border-radius: 20px;
	margin:3px;
	background: #004AAD;
	color: #004AAD;
	transition: 0.2s ease-out;
	width: auto;
	background: ${props => props.selected ? "#ffc500" : "#004AAD"};
	color: ${props => props.selected ? "#004AAD" : "#fff"};
	
	&:hover {
		background: #ffc500;
		transition: 0.2s ease-out;
		cursor: pointer;
	}

	@media screen and (min-width: 650px) {
		padding: 1rem 4rem;
		font-size: 1.3rem;
		flex-direction: row;
	}
`;

