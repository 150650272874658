// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDOVfuslpdqGuSDxsBJK5foL-14VhiqiFY",
  authDomain: "suerte-viajera.firebaseapp.com",
  projectId: "suerte-viajera",
  storageBucket: "suerte-viajera.appspot.com",
  messagingSenderId: "365314658196",
  appId: "1:365314658196:web:f9e2e1b561c566fde64981",
  measurementId: "G-8QYEHHNED0"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);