import { useEffect } from "react";
import { Link, Navigate, redirect } from "react-router-dom";

export default function NotFound() {




    return <Navigate to={"/"} ></Navigate>


    return (
        <div>
            <p>PÁGINA NO ENCONTRADA</p>
            <Link to='/'>Home</Link>
        </div>
    )
}