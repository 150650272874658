import React, { useState } from 'react';
import "./styles.css";
import useScreenSize from '../../../hooks/useScreenSize';
const Hero = () => {
    const { width, height } = useScreenSize();

    if (width >= 1440) {
        return (
            <div className="hero_container_desktop">
                <div className="desktop-hero-wrapper">
                    <div className="desktop-hero">
                        <div className="overlap-group">
                            <img
                                className="vector"
                                alt="Vector"
                                src="https://generation-sessions.s3.amazonaws.com/7c0f7d1676de0618b440c345dba18551/img/vector-6-1.svg"
                            />
                            <h1 className="text-wrapper">¡Tu momento de volar está cerca!</h1>
                        </div>
                        <p className="participa-en">
                            <span className="span">Participa en </span>
                            <span className="text-wrapper-2">nuestros sorteos y viaja</span>
                            <span className="span"> a los lugares que siempre has soñado.</span>
                        </p>
                        <img
                            className="group"
                            alt="Group"
                            src="https://generation-sessions.s3.amazonaws.com/7c0f7d1676de0618b440c345dba18551/img/group-14-1.png"
                        />
                        <img
                            className="img"
                            alt="Vector"
                            src="https://generation-sessions.s3.amazonaws.com/7c0f7d1676de0618b440c345dba18551/img/vector-9-1.svg"
                        />
                        <img
                            className="vector-2"
                            alt="Vector"
                            src="https://generation-sessions.s3.amazonaws.com/7c0f7d1676de0618b440c345dba18551/img/vector-9-1.svg"
                        />
                        <img
                            className="mask-group"
                            alt="Mask group"
                            src="https://generation-sessions.s3.amazonaws.com/7c0f7d1676de0618b440c345dba18551/img/mask-group-1@2x.png"
                        />
                        <p className="actualmente-nos">
                            <span className="text-wrapper-3">Actualmente nos encontramos en Perú y </span>
                            <span className="text-wrapper-2">tenemos más de 50 destinos</span>
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    if (width >= 768) {
        return (
            <div className="tablet-hero">
                <div className="overlap-group">
                    <img
                        className="vector"
                        alt="Vector"
                        src="https://generation-sessions.s3.amazonaws.com/49980b377ae686924d6db6fa8c899b09/img/vector-6-1.svg"
                    />
                    <p className="participa-en">Participa en nuestros sorteos y viaja a los lugares que siempre has soñado.</p>
                    <img
                        className="group"
                        alt="Group"
                        src="https://generation-sessions.s3.amazonaws.com/49980b377ae686924d6db6fa8c899b09/img/group-14-1.png"
                    />
                    <p className="tu-momento-de-volar">¡Tu momento de volar está cerca!</p>
                    <img
                        className="mask-group"
                        alt="Mask group"
                        src="https://generation-sessions.s3.amazonaws.com/49980b377ae686924d6db6fa8c899b09/img/mask-group-1@2x.png"
                    />
                    <img
                        className="img"
                        alt="Vector"
                        src="https://generation-sessions.s3.amazonaws.com/49980b377ae686924d6db6fa8c899b09/img/vector-10-1.svg"
                    />
                    <img
                        className="vector-2"
                        alt="Vector"
                        src="https://generation-sessions.s3.amazonaws.com/49980b377ae686924d6db6fa8c899b09/img/vector-10-1.svg"
                    />
                    <p className="actualmente-nos">
                        <span className="text-wrapper">Actualmente nos encontramos en Perú y </span>
                        <span className="span">tenemos más de 50 destinos</span>
                    </p>
                </div>
            </div>
        )
    }

    return (

        <div className="prelaunch_hero_spacing">
            <div className="prelaunch_hero">
                <p className="participa-en">Participa en nuestros sorteos y viaja a los lugares que siempre has soñado.</p>
                <p className="actualmente-nos">
                    <span className="text-wrapper">Actualmente nos encontramos en Perú y </span>
                    <span className="span">tenemos más de 50 destinos</span>
                </p>
                <div className="overlap-group">
                    <img
                        className="vector"
                        alt="Vector"
                        src="https://generation-sessions.s3.amazonaws.com/8c50d9a1d17e0cdd34445d583f350a33/img/vector-6-1.svg"
                    />
                    <p className="tu-momento-de-volar">¡Tu momento de volar está cerca!</p>
                    <img
                        className="img"
                        alt="Vector"
                        src="https://generation-sessions.s3.amazonaws.com/8c50d9a1d17e0cdd34445d583f350a33/img/vector-7-1.svg"
                    />
                </div>
                <img
                    className="vector-2"
                    alt="Vector"
                    src="https://generation-sessions.s3.amazonaws.com/8c50d9a1d17e0cdd34445d583f350a33/img/vector-9-1.svg"
                />
                <img
                    className="vector-3"
                    alt="Vector"
                    src="https://generation-sessions.s3.amazonaws.com/8c50d9a1d17e0cdd34445d583f350a33/img/vector-9-1.svg"
                />
                <img
                    className="group"
                    alt="Group"
                    src="https://generation-sessions.s3.amazonaws.com/8c50d9a1d17e0cdd34445d583f350a33/img/group-14-1.png"
                />
            </div>


        </div>

    )
}

export default Hero;