import React, { useState } from 'react';
import "./styles.css";
import useScreenSize from '../../../hooks/useScreenSize';

const Footer = () => {

    const { width, height } = useScreenSize();

    if (width >= 1440) {
        return (
            <div className="prelaunch_footer">
                <div className={`desktop-footer`}>
                    <img
                        className="logo-suerte-viajera"
                        alt="Logo suerte viajera"
                        src="https://generation-sessions.s3.amazonaws.com/b0fdd278c712f2b0e8c2299564eae6cf/img/logo-suerte-viajera-2-1@2x.png"
                    />
                    <img
                        className="ic-baseline-facebook"
                        alt="Ic baseline facebook"
                        src="https://generation-sessions.s3.amazonaws.com/b0fdd278c712f2b0e8c2299564eae6cf/img/ic-baseline-facebook-1.svg"
                    />
                    <img
                        className="mdi-instagram"
                        alt="Mdi instagram"
                        src="https://generation-sessions.s3.amazonaws.com/b0fdd278c712f2b0e8c2299564eae6cf/img/mdi-instagram-1.svg"
                    />
                    <img
                        className="mdi-youtube"
                        alt="Mdi youtube"
                        src="https://generation-sessions.s3.amazonaws.com/b0fdd278c712f2b0e8c2299564eae6cf/img/mdi-youtube-1.svg"
                    />
                    <img
                        className="ic-baseline-tiktok"
                        alt="Ic baseline tiktok"
                        src="https://generation-sessions.s3.amazonaws.com/b0fdd278c712f2b0e8c2299564eae6cf/img/ic-baseline-tiktok-1.svg"
                    />
                </div>
            </div>
        )
    }

    if (width >= 768) {
        return (
            <div className="prelaunch_footer">

                <div className={`tablet-footer`}>
                    <img
                        className="logo-suerte-viajera"
                        alt="Logo suerte viajera"
                        src="https://generation-sessions.s3.amazonaws.com/0a618c56d70fcb566d04454d3ed51ca1/img/logo-suerte-viajera-2-1@2x.png"
                    />
                    <img
                        className="ic-baseline-facebook"
                        alt="Ic baseline facebook"
                        src="https://generation-sessions.s3.amazonaws.com/0a618c56d70fcb566d04454d3ed51ca1/img/ic-baseline-facebook-1.svg"
                    />
                    <img
                        className="mdi-instagram"
                        alt="Mdi instagram"
                        src="https://generation-sessions.s3.amazonaws.com/0a618c56d70fcb566d04454d3ed51ca1/img/mdi-instagram-1.svg"
                    />
                    <img
                        className="mdi-youtube"
                        alt="Mdi youtube"
                        src="https://generation-sessions.s3.amazonaws.com/0a618c56d70fcb566d04454d3ed51ca1/img/mdi-youtube-1.svg"
                    />
                    <img
                        className="ic-baseline-tiktok"
                        alt="Ic baseline tiktok"
                        src="https://generation-sessions.s3.amazonaws.com/0a618c56d70fcb566d04454d3ed51ca1/img/ic-baseline-tiktok-1.svg"
                    />
                    <div className="redes-sociales">Redes sociales</div>
                </div>

            </div>

        )
    }

    return (
        <div className="prelaunch_footer">
            <div className={`mobile-footer`}>
                <img
                    className="logo-suerte-viajera"
                    alt="Logo suerte viajera"
                    src="https://generation-sessions.s3.amazonaws.com/5721dbc9c811046ae6be462d0b0b53b0/img/logo-suerte-viajera-2-1@2x.png"
                />
                <img
                    className="ic-baseline-facebook"
                    alt="Ic baseline facebook"
                    src="https://generation-sessions.s3.amazonaws.com/5721dbc9c811046ae6be462d0b0b53b0/img/ic-baseline-facebook-1.svg"
                />
                <img
                    className="mdi-instagram"
                    alt="Mdi instagram"
                    src="https://generation-sessions.s3.amazonaws.com/5721dbc9c811046ae6be462d0b0b53b0/img/mdi-instagram-1.svg"
                />
                <img
                    className="mdi-youtube"
                    alt="Mdi youtube"
                    src="https://generation-sessions.s3.amazonaws.com/5721dbc9c811046ae6be462d0b0b53b0/img/mdi-youtube-1.svg"
                />
                <img
                    className="ic-baseline-tiktok"
                    alt="Ic baseline tiktok"
                    src="https://generation-sessions.s3.amazonaws.com/5721dbc9c811046ae6be462d0b0b53b0/img/ic-baseline-tiktok-1.svg"
                />
                <div className="redes-sociales">Redes sociales</div>
            </div>
        </div>
    )
}

export default Footer;
