import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import Tickets from './pages/Tickets';
import { GlobalStyle } from './globalStyles';
import Pdp from './pages/Pdp/Pdp';
import { useLayoutEffect } from 'react';
import TerminosView from './pages/static/TerminosView';
import QuienesSomosView from './pages/static/QuienesSomosView';
import VuelosView from './pages/VuelosView/VuelosView';
import AuthProvider from './context/AuthProvider/Index';
import SignIn from './pages/Signin';
import SignUp from './pages/Signup';
import Profile from './pages/Profile';
import NotFound from './pages/NotFound/NotFound';

function App() {
	const Wrapper = ({ children }) => {
		const location = useLocation();
		useLayoutEffect(() => {
			document.documentElement.scrollTo(0, 0);
		}, [location.pathname]);
		return children
	}

	return (
		<Router>
			<Wrapper>
				<AuthProvider>
					<GlobalStyle />
					<Routes>
						<Route
							path="/"
							element={
								<Home></Home>
							}
						/>
						<Route
							path="/terminos-y-condiciones"
							element={
								<TerminosView></TerminosView>
							}
						/>
						<Route
							path="/quienes-somos"
							element={
								<QuienesSomosView></QuienesSomosView>
							}
						/>
						<Route path='*' element={<NotFound />} />
					</Routes>
				</AuthProvider>
			</Wrapper>


		</Router >

	);
}

export default App;
