import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  sendEmailVerification,
  reauthenticateWithCredential
} from "firebase/auth";
import { app } from "./firebaseConfig";

export const auth = getAuth(app);

export const CreateUserFirebase = async (email, password) =>
  await createUserWithEmailAndPassword(auth, email, password);

export const logIn = async (email, password) =>
  await signInWithEmailAndPassword(auth, email, password);

export const logOut = async () => await signOut(auth);

export const CallStateAuth = (callback) => {
  return onAuthStateChanged(auth, callback);
};

export const sendVerificationUser = async (user) => await sendEmailVerification(user);

const provider = new GoogleAuthProvider();
//provider.addScope('https://www.googleapis.com/auth/');

export const signInWithGoogle = async () =>
  await signInWithPopup(auth, provider);
