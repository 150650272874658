import React, { useState, useEffect } from "react";
import "./styles.css";

export const Countdown = () => {
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [inputDate, setInputDate] = useState("29 May 2023");
    const [currentDate, setCurrentDate] = useState(inputDate);

    useEffect(() => {
        const changingDate = new Date(inputDate);
        const currentDate = new Date();
        const totalSeconds = (changingDate - currentDate) / 1000;

        setDays(formatTime(Math.floor(totalSeconds / 3600 / 24)));
        setHours(Math.floor(totalSeconds / 3600) % 24);
        setMinutes(Math.floor(totalSeconds / 60) % 60);
        setSeconds(Math.floor(totalSeconds % 60));
    }, [currentDate]);

    function formatTime(time) {
        return time < 10 ? `0${time}` : time;
    }


    return (
        <div className="countdown-values">
            {
                days == 0 ? (<></>) : (<div className="countdown-value">
                    <p className="big-text">{days}</p>
                    <span>días</span>
                </div>)
            }

            <div className="countdown-value">
                <p className="big-text">{hours}</p>
                <span>horas</span>
            </div>
            <div className="countdown-value">
                <p className="big-text">{minutes}</p>
                <span>minutos</span>
            </div>
            <div className="countdown-value">
                <p className="big-text">{seconds}</p>
                <span>segundos</span>
            </div>
        </div>
    );
};