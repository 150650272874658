import React, { useState } from 'react';
import NavBar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';
const Header = () => {
    const [isOpen, setIsOpen] = useState(false);

	const toggle = () => {
		setIsOpen(!isOpen);
	};
    return (
        <div>
            <NavBar toggle={toggle} />
			<Sidebar isOpen={isOpen} toggle={toggle} />
        </div>
    )
}

export default Header;