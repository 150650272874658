import React, { useContext, useEffect, useState } from 'react';
import { Bars, IconLinkLogin, IconLinkRegister, IconNavbar, IconsLink, Nav, NavContainer, NavIcon, NavLink } from './NavbarElements';
import './styles.css'
import { useAuthContext } from '../../context/AuthContext/Index';
import { RiMenuFill } from 'react-icons/ri';
function NavBar({ toggle }) {

	const { user } = useAuthContext()
	const [headerchange, setHeaderChange] = useState(true)
	const listenScrollEvent = (event) => {
		if (window.scrollY < 503) {
			return setHeaderChange(false)
		} else if (window.scrollY > 500) {
			return setHeaderChange(true)
		}
	}
	/*
		useEffect(() => {
			//window.addEventListener('scroll', listenScrollEvent);
	
			return () =>
				window.removeEventListener('scroll', listenScrollEvent);
		}, []);
	*/


	return (
		<>
			<Nav navChanged={headerchange} pathname={window.location.pathname}>
				<NavContainer>
					<NavLink to='/'>
						<img src='https://i.imgur.com/kcnPB47.png' className='logo_header'></img>
					</NavLink>
					{
						user != undefined ? (<IconsLink>

							<RiMenuFill style={{ width: '20px', height: '20px', color: '#004AAD', cursor: 'pointer' }} onClick={toggle}></RiMenuFill>
						</IconsLink>) : (<IconsLink>
							<IconLinkLogin href='/vuelos'>
								Vuelos
							</IconLinkLogin>
							<IconLinkRegister href='/#suscribete'>
								Suscribete
							</IconLinkRegister>
						</IconsLink>)
					}

				</NavContainer>


			</Nav>
		</>
	);
}

export default NavBar;
