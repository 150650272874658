import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    *{
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-family: "Kanit", sans-serif;
        font-family: 'Raleway', sans-serif;
        scroll-behavior: smooth;
    }
    .container_content{
        margin-top: 60px;
        
    }


`;
