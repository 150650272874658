import React from "react";
import "./styles.css";
import useScreenSize from "../../../hooks/useScreenSize";

export const Cintillo = () => {

    const { width, height } = useScreenSize();


    if (width >= 1440) {
        return (
            <img src="https://i.imgur.com/YTMvdpz.gif" alt="gif_lugares" className="prelaunch_cintillo" />
        )
    }

    if (width >= 768) {
        return (
            <img src="https://i.imgur.com/iDW0jel.gif" alt="gif_lugares" className="prelaunch_cintillo" />
        )
    }

    return (
        <img src="https://i.imgur.com/MIx255V.gif" alt="gif_lugares" className="prelaunch_cintillo" />
    );
};

