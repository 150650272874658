import React, { useState } from 'react';
import {
	ProductsContainer,
	SelectedCategoryHeading,
	CategoryHeading,
	ProductsWrapper,
	ProductsCard,
	ProductsImg,
	ProductsInfo,
	ProductsTitle,
	ProductsDesc,
	ProductsPrice,
	ProductsButton,
	CategoryButton,
} from './ProductsElements';
import { Link } from 'react-router-dom';


export const ChooseDestination = ({ heading, dataNacionales, dataInternacionales }) => {

	const [destination, setDestination] = useState('Nacionales')

	return (
		<div id="destinations">
			<ProductsContainer>
				<CategoryHeading>
					<SelectedCategoryHeading>{heading}</SelectedCategoryHeading>
					<CategoryButton selected={destination === "Nacionales"} onClick={() => { setDestination("Nacionales") }}>Nacionales</CategoryButton>
					<CategoryButton selected={destination === "Internacionales"} onClick={() => { setDestination("Internacionales") }}>Internacionales</CategoryButton>
				</CategoryHeading>
				{
					destination == 'Nacionales' ? (
						<Products data={dataNacionales}></Products>

					) : (
						<Products data={dataInternacionales} ></Products>)
				}

			</ProductsContainer>
		</div>





	)
}



function Products({ data }) {
	return (

		<ProductsWrapper>
			{data.map((product, index) => {
				return (
					<ProductsCard key={index} to="/indo">
						<ProductsImg src={product.img_url} alt={product.name} />
						<ProductsInfo>
							<ProductsTitle>{product.name}</ProductsTitle>
							<ProductsDesc>{product.description}</ProductsDesc>
							<ProductsPrice>S/{product.priceUnid}</ProductsPrice>
							<Link to={`/vuelos/${product.id}`}>
								<ProductsButton>PARTICIPAR</ProductsButton>
							</Link>
						</ProductsInfo>
					</ProductsCard>
				);
			})}
		</ProductsWrapper>
	);
}

export default Products;
