import React, { useEffect } from 'react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import './styles.css'
const QuienesSomosView = () => {

    useEffect(() => {




    }, [])



    return (
        <>
            <Header></Header>
            <div className='container_terms'>
                <h3 className='title_section'>
                    ¿Quiénes somos?
                </h3>
                <p>

                    Suerte Viajera es una plataforma web dedicada a los apasionados de los viajes que desean explorar nuevos destinos de una manera rápida y emocionante. Nuestro emprendimiento se enfoca en brindar a nuestros usuarios la oportunidad de ganar increíbles viajes nacionales e internacionales a través de sorteos diarios.

                    En nuestra página web, encontrarás una amplia variedad de sorteos de viajes cuidadosamente seleccionados, que abarcan desde fascinantes destinos locales hasta exóticos paraísos internacionales.

                    En cada sorteo, la suerte es el factor clave. Cada participante tiene una oportunidad justa y emocionante de ganar un viaje de ensueño. Nuestro sistema de selección de ganadores es transparente y confiable, asegurando que todos los participantes tengan las mismas posibilidades de vivir una aventura inolvidable.
                    ¡Prueba tu suerte y embarcarte en una aventura inolvidable!

                </p>






            </div>




            <Footer />
        </>
    )
}

export default QuienesSomosView