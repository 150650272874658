import React from 'react';
import {
	SidebarContainer,
	Icon,
	CloseIcon,
	SidebarMenu,
	SidebarLink,
	SideBtnWrap,
	SidebarRoute,
} from './SideBarElements';

import './styles.css'
import { logOut, signInWithGoogle } from '../../api/authFirebase';
import { useContext } from 'react';
import { useAuthContext } from '../../context/AuthContext/Index';
import { CreateUser } from '../../api/userFirebase';
import { removeToken } from '../../helpers/helpers';
import { useNavigate } from 'react-router-dom';

const LoginBtn = ({ onClick }) => {
	return (
		<div id="customBtn" class="customGPlusSignIn" onClick={onClick}>
			<span class="icon"></span>
			<span class="buttonText">Google</span>
		</div>
	)
}

function Sidebar({ isOpen, toggle }) {


	const { user, setUser } = useAuthContext();
	const navigate = useNavigate();

	//const { user, authSuccces, logoutAuth } = useContext(AuthContext)
	const handleClickAuthGoogle = async () => {

		await signInWithGoogle()
			.then(async (res) => {
				console.log("res", res)
				//authSuccces(res.user);
				/*const cookieUser = {
					email: res.user.email,
					emailVerified: res.user.emailVerified,
					displayName: res.user.displayName,
					uid: res.user.uid
				}
				const resCreated = await CreateUser(cookieUser)

				if (resCreated) {
					//alert("Se creo un usuario")
				}*/


				// create user en db firestore
			})
			.catch((error) => {
				const errorCode = error.code;
				const errorMessage = error.message;
				console.log("error");
				console.log(errorCode, errorMessage);
			});
	};

	const logOutSesion = () => {
		removeToken();
		setUser(undefined)
		navigate("/signin", { replace: true });
	};

	return (
		<SidebarContainer isOpen={isOpen}>
			<Icon onClick={toggle}>
				<CloseIcon />
			</Icon>
			{
				user == undefined ? (<div className='container_btn_login'>
					<strong>Hola viajero,</strong> <br></br>
					Inicia sesión para participar
					<LoginBtn onClick={handleClickAuthGoogle}></LoginBtn>
				</div>) : (
					<div className='container_sidebar_profile'>
						<img className='side_bar_img_profile' src={user?.photoURL}></img>
						{user?.username}
						<button onClick={logOutSesion} className='side_bar_btn_logout' >Salir</button>

					</div>)
			}


			<SidebarMenu>
				<SidebarLink to='/#destinations'>Home</SidebarLink>
				<SidebarLink to='/mistickets'>Mis tickets</SidebarLink>
				<SidebarLink to='/perfil'>Mi Perfil</SidebarLink>
			</SidebarMenu>
			<SideBtnWrap>
				<SidebarRoute to='/#destinations'>A DÓNDE?</SidebarRoute>
			</SideBtnWrap>
		</SidebarContainer>
	);
}

export default Sidebar;
