import React, { useState } from 'react';
import "./styles.css";
import { useEffect } from 'react';
import useScreenSize from '../../../hooks/useScreenSize';
const Header = () => {
    const { width, height } = useScreenSize();

    /*if (width >= 1440) {
        return (
            <div className={`tablet-hero`}>
                desktop
            </div>
        )
    }

    if (width >= 768) {
        return (
            <div className={`tablet-hero`}>
                tablet
            </div>
        )
    }*/
    return (
        <div className="prelaunch_header">
            <div className="overlap">
                <div className="group">
                    <div className="overlap-group">
                        <a className="suscribete" href="https://api.whatsapp.com/send?phone=51941838854&text=Hola%20Suerte%20Viajera%2C%20deseo%20que%20me%20apuntes%20para%20tener%20mi%20ticket%20gratis%20en%20mi%20primera%20jugada.%20%F0%9F%A4%A9" >Suscríbete</a>
                    </div>
                </div>
                <img
                    className="logo-suerte-viajera"
                    alt="Logo suerte viajera"
                    src="https://generation-sessions.s3.amazonaws.com/980a15f0ec96d02211fc3d0e1f2ba0d8/img/logo-suerte-viajera-1-1@2x.png"
                />
            </div>
            <img
                className="vector"
                alt="Vector"
                src="https://generation-sessions.s3.amazonaws.com/980a15f0ec96d02211fc3d0e1f2ba0d8/img/vector-1-1.svg"
            />
        </div>
    )
}

export default Header;