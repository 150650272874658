import React, { useEffect } from 'react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import './styles.css'
const TerminosView = () => {

    useEffect(() => {




    }, [])



    return (
        <>
            <Header></Header>
            <div className='container_terms'>
                <h3 className='title_section'>
                    INFORMACIÓN RELEVANTE
                </h3>
                <p>
                    Es requisito necesario para la participación en nuestras rifas que se ofrecen en este sitio, que lea y acepte los siguientes Términos y Condiciones que a continuación se redactan. El uso de nuestros servicios, así como la compra de nuestros tickets implicará que usted ha leído y aceptado los Términos y Condiciones de Uso en el presente documento. Para adquirir un ticket, será necesario el registro por parte del usuario, con ingreso de datos personales fidedignos y definición de una contraseña.

                    El usuario puede elegir y cambiar la clave para su acceso de administración de la cuenta en cualquier momento, en caso de que se haya registrado y que sea necesario para la compra de alguno de nuestras rifas. https://suerteviajera.com no asume la responsabilidad en caso de que entregue dicha clave a terceros.

                    Todas las compras y transacciones que se lleven a cabo por medio de este sitio web, están sujetas a un proceso de confirmación y verificación, el cual podría incluir la verificación de número de tickets disponibles, validación de la forma de pago, validación de la factura (en caso de existir) y el cumplimiento de las condiciones requeridas por el medio de pago seleccionado. En algunos casos puede que se requiera una verificación por medio de correo electrónico.

                    Los precios de los tickets ofrecidos en esta plataforma son válido únicamente en las compras realizadas en este sitio web.

                    POLÍTICAS DE SORTEO

                    Todos nuestros sorteos contarán SI o SI con un ganador, pero, las fechas estimadas en iniciar un sorteo pueden verse afectadas  por lo siguiente:

                    Cuando hasta la fecha programada del inicio de un sorteo no se ha vendido más del 50% de tickets  disponibles.

                    En algunas ocasiones Suerte Viajera puede cambiar las fechas programadas del inicio de un sorteo, esto puede suceder sin previo aviso, por eso se recomienda leer frecuentemente los Términos y Condiciones que se describen en este documento.
                </p>

                <h3>
                    POLÍTICAS DE REEMBOLSO
                </h3>

                <p>

                    Si Suerte Viajera reprograma una fecha estimada de inicio de sorteo, Usted como participante tiene todo el derecho de exigir el reembolso de su pago, en ocasiones el reembolso no será del 100%, ya que se aplican cargos por el uso de nuestra plataforma de compras con tarjeta. Las tarifas de reembolso son las siguientes:
                    Si usted pago con transferencia bancaria o depósito directo a cuenta el reembolso será del 100%
                    Si usted pago con Yape  el reembolso será del 100%
                    El reembolso puede tardar hasta 48 horas después de que se haya  solicitado, este mismo puede ser mediante correo electrónico a soporte@suerteviajera.com o al  número de WhatsApp 941838854

                </p>

                <h3>
                    SELECCIÓN DE GANADORES
                </h3>

                <p>

                    Se eligen a los ganadores manera ALEATORIA, esto puede sonar estafa, por ello utilizamos una aplicación de Microsoft Store para seleccionar a los ganadores, Draw Lucky  es la aplicación que usamos para generar los números aleatorios y elegir a un ganador, puedes revisar la aplicación y probar tu mismo su funcionamiento transparente. Puedes conocer más de esta aplicación, descargándola aquí
                    Cabe resaltar que cada sorteo incluye una verificación de un notario y un representante de la compañía encargada de validar los datos del usuario

                    VIAJE NACIONAL:
                    · Podrán participar todas las personas naturales mayores de 18 años que residan en Perú.
                    · El costo por la participación es de S/5 (Inc. IGV).
                    · El juego consiste en elegir n cantidad de tickets distintos de un total de 100 tickets..
                    · El premio para el ganador consiste en un VIAJE NACIONAL (PERÚ) – Ida/Vuelta para dos (02) personas mayores de 18 años.
                    · El premio SOLO INCLUYE equipaje de mano para ambas personas(02). No incluye seguro médico, equipo adicionales y/o servicios adicionales en la aerolínea. Así mismo, es posible que el premio contenga escalas para el destino final.
                    · Para ganar el premio mayor, el usuario deberá acertar el número del ticket ganandor del sorteo.
                    · El juego es diario, por lo que, inicia a las 00:00 p.m. y los resultados se muestran a las 12:00 p.m. del siguiente día.
                    VIAJE  INTERNACIONAL
                    · Podrán participar todas las personas naturales mayores de 18 años que residan en Perú.
                    · El costo por la participación es de S/10(Inc. IGV).
                    · El juego consiste en elegir n cantidad de tickets distintos de un total de 150 tickets.
                    · El premio para el ganador consiste en un VIAJE INTERNACIONAL (PERÚ - LATINOAMERICA) – Ida/Vuelta para dos (02) personas mayores de 18 años.
                    · El premio SOLO INCLUYE equipaje de mano para ambas personas(02). No incluye seguro médico, equipo adicionales y/o servicios adicionales en la aerolínea. Así mismo, es posible que el premio contenga escalas para el destino final.
                    · Para ganar el premio mayor, el usuario deberá acertar el número del ticket ganandor del sorteo.
                    · El juego es diario, por lo que, inicia a las 00:00 p.m. y los resultados se muestran a las 12:00 p.m. del siguiente día.
                </p>

                <h3>
                    USO DE IMAGEN Y DATOS
                </h3>
                <p>
                    Al aceptar nuestros Términos y condiciones en este sitio web, aceptas el uso de imagen y datos personales, siempre en cuando salgas elegido como ganador de algunos de nuestros premios, los datos e imagen serán usados para campañas publicitarias y/o testimonios en nuestro sitio web. De esta manera fomentaremos la confianza con nuestros futuros participantes en Suerte Viajera

                </p>

                <h3>
                    CONTACTO A GANADORES DE SORTEO
                </h3>
                <p>

                    El departamento de Atención al Cliente de Bitel (123), se contactará vía telefónica con los ganadores mayores a S/ 100 soles., bajo el siguiente procedimiento:
                    · Se solicitarán los siguientes datos: Nombre y apellidos completos, número de documento de identificación (DNI/CE/Pasaporte), cuenta bancaria (CCI), correo electrónico y domicilio.
                    · Si el ganador no responde la llamada o su teléfono se encuentra apagado, se le volverá a llamar tres (03) veces más (01) llamadas por día, 3 llamadas en total). Si luego de ello no se logra contactar con el ganador, este perderá el derecho a reclamar el premio.
                    · Si el ganador reside en Lima, será invitado a la oficina principal ubicada en Calle 21, N° 878, distrito de San Isidro, según los plazos que se indican en  el numeral 7. El ganador deberá asistir con su documento de identidad en físico y una fotocopia de este. Asimismo, deberá firmar el acta de entrega del premio, y posterior a eso, se realizará la transferencia.
                    ·  En caso el ganador resida en provincia, antes de realizar la transferencia, tendrá  que acercarse al punto de venta indicado por correo para realizar la firma del acta de entrega del premio. Posteriormente, se realizará mediante una transferencia bancaria.


                </p>

                <h3>
                    COMPRA DE TICKETS CON PAGO EN EFECTVO
                </h3>

                <p>
                    Si usted hizo la compra de algunos de nuestros tickets y elige la opción pago en efectivo, nuestro sistema reservara los tickets (números) hasta 24 horas después de haber registrado su compra, si en este lapso de tiempo usted no confirma el pago, nuestro sistema liberará nuevamente los tickets (números) para ponerlos de reservados a disponibles, de esta manera otro participante podrá comprar estos tickets (números).

                </p>

                <h3>
                    USO DE LA WEB SOLO PARA MAYORES DE 18 AÑOS

                </h3>

                <p>
                    Nuestro sistema de rifas solo puede ser usado por personas mayores de 18 años, no fomentamos el uso de nuestra plataforma a menores de edad por ser de uso exclusivo para mayores de edad (18 años). Si eres menor de edad y deseas participar en nuestros sorteos te recomendamos que busques la compañía de un adulto y te guíe en el uso responsable de nuestra plataforma.

                </p>

                <h3>
                    PRIVACIDAD
                </h3>

                <p>
                    En https://www.suerteviajera.com garantizamos que la información personal que usted envía cuenta con la seguridad necesaria. Los datos ingresados por usuario cuentan con CERTIFICADO SSL (Secure Sockets Layer)  esta es una capa de conexión segura es un estándar de seguridad global que permite la transferencia de datos cifrados entre un navegador y un servidor web (para pagos con tarjeta).

                    La suscripción a boletines de correos electrónicos publicitarios es voluntaria y podría ser seleccionada al momento de crear su cuenta.

                    Suerte viajera se reserva los derechos de cambiar o de modificar estos términos sin previo aviso.
                </p>














            </div>




            <Footer />
        </>
    )
}

export default TerminosView